import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'

const { HOME } = require('@/constants/misc')

export default function router () {
  const routes = [
    {
      path: '/',
      name: HOME,
      components: {
        page: HomeView
      }
    }
  ]

  return createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
}
