export default {
  state: {
    windowWidth: window.innerWidth,
  },

  getters: {},

  mutations: {
    setWindowWidth (state) {
      state.windowWidth = window.innerWidth
    }
  },

  actions: {}
}
