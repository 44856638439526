<template>
  <q-page :class="pageClass">
    <div class="wrapper">
      <router-view name="page" v-slot="{ Component }" >
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>

      <div v-if='pageClass === "app-page"' class='promo-wrapper'>
        <Promo :height='65' />
      </div>
    </div>
  </q-page>
</template>

<script>
import Promo from '@/components/Promo'
const { HOME } = require('@/constants/misc')

export default {
  computed: {
    pageClass () {
      switch (this.$route.name) {
        case HOME: return 'home-page'

        default: return 'app-page'
      }
    }
  },
  components: {
    Promo
  }
}
</script>

<style scoped lang="scss">
.app-page {
  background: var(--color-light-bg);
  color: var(--color-black);
  padding: 3rem;

  .wrapper {
    max-width: var(--spacing-max-content-width);
    margin: auto;

    min-height: 70vh;

    .promo-wrapper {
      bottom: 0;
      padding-top: 40px;
      text-align: center;
    }
  }
}

@media (max-width: 650px) {
  .app-page {
    padding: 3rem 0;
  }
}

.about-page {}

</style>
