const { TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH } = require('../../constants/tools')
const { HOME } = require('../../constants/misc')

module.exports = {
  nav: {
    [HOME]: 'Home',
    api: 'API',
    blog: 'Blog',
    docs: 'Docs',
    contact: 'Contact',
    [TIMELINE]: 'Timeline',
    [HASHTAGS]: 'Hashtags',
    [LINKS]: 'Link Counter',
    [ACTIVITY]: 'Activity',
    [SEARCH]: 'Search'
  },
  homepage: {
    heading: {
      title: 'Analyze hate and disinformation online',
      intro: 'The Social Media Analysis Toolkit (SMAT) was designed to help facilitate activists, journalists, researchers, and other social good organizations to analyze and visualize harmful online trends such as hate, mis-, and disinformation.',
    },
    services: {
      analysis: {
        title: 'Analysis tools',
        description: 'Investigate and visualize trends across one or more online sources',
        link: 'Run an analysis'
      },
      search: {
        title: 'Search',
        description: 'Search social media websites for key terms and read posts',
        link: 'Start a search'
      },
      api: {
        title: 'API',
        description: 'For advanced use cases, request data with custom queries',
        link: 'Get started'
      }
    },
    featured: {
      title: 'As seen in :'
    },
    how: {
      header: 'How does it work'
    },
    about: {
      value1: {
        title: 'Easy and rigorous tools for analyzing the dangerous fringe',
        detail: 'SMAT pulls data from the corners of the internet most know for generating harmful narratives and real world violence such as Parler, Telegram, 8kun, 4chan, and Gab. We then provide straightforward ways to analyze and visualize that data for researchers, activists, and organizations concerned with monitoring emerging threats.'
      },
      value2: {
        title: 'Analysis tools',
        detail: 'Investigate trends using a range of data visualization tools currently including: Timeline, Link Counter, Hashtag Detector, and the ability to find top users and sub-forums discussing a given topic.',
        link: 'Run an analysis'
      },
      value3: {
        title: 'Search',
        detail: 'Quickly search key terms and read posts on a variety of platforms. Pivot between new leads without having to read through harmful content in its original location',
        link: 'Start a search'
      },
      value4: {
        title: 'API',
        detail: 'Use custom boolean queries and receive raw JSONs of data. Free and public access with generous rate limiting. Receive raw content or perform a variety of aggregations.',
        link: 'Get Started'
      },
      premium: {
        title: 'Premium offerings',
        detail: 'For advanced use cases we offer a range of services including: large custom data downloads, access to advanced analysis dashboards, alert reporting, investigations, OSINT training, new dataset additions, high traffic API access, and a variety of AI classification services. New services and free offerings are being added constantly. {0} for updates and exclusive investigations.',
        linkText: 'Follow our blog',
        link: 'Contact us'
      },
      us: {
        heading: 'About us',
        title: 'We are a collective of researchers, hackers, and activists devoted to serving the interests of marginalized communities'
      },
      contact: {
        title: 'Contact Us',
        detail: 'Find us on {gitHosting} or {twitter} on Twitter. SMAT is fiscally hosted and small or large contributions can be made through our {donation} page which help to develop new tools and data sources. Descriptions of bugs or desired features welcomed as well!'
      },
      opencollective: {
        title: 'We are a part of Open Collective',
        detail: 'We welcome donations to our open source and free work through our {0} where we transparently track our finances.',
        linkText: 'Open Collective page',
      },
      opensource: {
        title: 'Our tools are open source',
        detail: 'All our tools are {0} so you can see our work and modify it for your own needs.',
        linkText: 'open source'
      },
      partners: {
        title: 'Our Partners:'
      }
    }
  },
  sidebar: {
    title: 'Edit analysis'
  },
  modal: {
    title: 'Edit query'
  },
  toolPicker: {
    label: 'Analysis Type',
    changeTools: 'See more options',
    modalTitle: 'Choose a tool to begin your analysis',
    modalSubtitle:
      'Each tool below will run a different analysis based on your input and return downloadable results'
  },
  toolDescription: {
    [TIMELINE]:
      'The timeline functionality takes a search term and makes a graph of how often it was written over time',
    [HASHTAGS]:
      'The hashtag functionality takes a search term and creates a bar graph of related hashtags',
    [LINKS]:
      'The link counter functionality takes a search term and returns a bar graph counting links in comments with that term',
    [ACTIVITY]:
      'This takes a search term and creates a bar graph of a particular field. For most sources this is the author of the content.',
    [SEARCH]:
      'The search tool finds examples of comments containing your search term and displays them.'
  },
  consent: {
    contentWarning: 'Content Warning: The sites that SMAT primarily focuses on have very harmful content on them. They are mostly far-right and conspiracy linked including to militia organizations. SMAT data is not recommended to be viewed by persons under 18 years old.',
    consentReminder: 'Search is disabled until you consent to the content warning.',
    confirmConsent: 'Confirm consent'
  },
  setting: {
    term: 'Search term',
    termPlaceholder: 'Enter search term',
    startDate: 'Start date',
    endDate: 'End date',
    pickWebsite: 'Social media website to search',
    twitterVerifiedNote: 'Note: our tools only analyze tweets from verified Twitter accounts.',
    aggBy: {
      label: 'Aggregate Reddit by',
      author: 'Author',
      subreddit: 'Subreddit'
    },
    numberHashtags: 'Number of hashtags',
    numberUrls: 'Number of urls',
    interval: 'Interval',
    times: {
      hour: 'hour',
      day: 'day',
      week: 'week',
      month: 'month'
    },
    timely: {
      hour: 'Hourly',
      day: 'Daily',
      week: 'Weekly',
      month: 'Monthly'
    },
    limit: 'Limit',
    showChangepoint: 'Show changepoint',
    button: {
      timeline: 'See timeline results',
      hashtags: 'See hashtag detector results',
      urls: 'See URLs graph',
      activity: 'See activity',
      search: 'See search results'
    }
  },
  chart: {
    timelineOn: 'Timeline on ',
    hashtagsWith: 'Hashtags Occurring with ',
    linkCountOn: 'Link Count on ',
    activityOn: 'Activity on ',
    postsPer: "Posts containing '{term}' per {interval}",
    popularLinksWith: "Popular links occurring with '{term}'",
    termAggBy: "'{term}' aggregated over {aggBy}s",
    downloadPNG: 'Save as PNG'
  },
  table: {
    date: 'Date',
    usage: 'Usage',
    urls: 'URLs',
    urlCount: 'URL count',
    user: 'User',
    count: 'Count',
    hashtags: 'Hashtags',
    hashtagFreq: 'Hashtag frequency',
    viewFor: 'Table View for ',
    view: 'Table View',
    downloadCSV: 'Download CSV'
  },
  searchResults: {
    yourSearch: "Posts from {site} containing '{term}'",
    maxResultsNote: 'Maximum of 50 comments shown.',
    seeOriginal: 'See original',
    noResults: 'Try a different search term or larger time interval.'
  },
  data: {
    beforeChangepoint: 'Before {site} changepoint',
    afterChangepoint: 'After {site} changepoint'
  }
}
