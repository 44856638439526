module.exports = {
  title: 'Sobre SMAT',
  subtitle: 'Fácil comprensión de las conversaciones en línea.',
  intro: 'El Kit de herramientas para análisis de redes sociales (SMAT) fue diseñado para apoyar a personas activistas, periodistas, investigadoras y organizaciones para analizar y visualizar tendencias amplias en diferentes plataformas.',
  value1: {
    title: 'Que tenemos',
    detail: 'El Kit de herramientas para análisis de redes sociales (SMAT) fue diseñado para apoyar a personas activistas, periodistas, investigadoras y organizaciones para analizar y visualizar tendencias amplias en diferentes plataformas. '
  },
  value2: {
    title: 'Sencilla y rigurosa',
    detail: 'Las herramientas han sido diseñadas para ser eficientes y simples, para que quienes las utilicen puedan responder rápidamente a la desinformación y las campañas de odio mientras se propagan, promoviendo que el desarrollo de investigaciones académicas sobre el tema.'
  },
  value3: {
    title: 'Open-source',
    detail: 'Nuestras herramientas son de {openSource} para que pueda ver cómo estamos trabajando y adaptarlas a sus necesidades.',
    openSource: 'open source'
  },
  contact: {
    title: 'Salúdanos',
    detail: 'Encuéntranos en {gitHosting} o salúdanos en info[at]smat-app.com or @smat_app en Twitter. SMAT está alojado fiscalmente y las contribuciones se pueden realizar a través de nuestra página de {donation}.'
  },
  contributors: {
    title: 'En este proyecto han colaborado',
    detail: 'Emmi Bevensee, {max}, Harry Momand, Jason Baumgartner/Pushshift, Quinn Dougherty, Jeremy Blackburn, ZC, {jacob}, Alex K., {protozoa}, Zach!, y más personas. Herramientas creadas con {pushshift}. Inspiradas por {slackbot}. Diseños de {mek}.'
  },
  partners: {
    title: 'Our Partners'
  }
}
