module.exports = {
  title: 'Analyze hate and disinformation online',
  intro: 'The Social Media Analysis Toolkit (SMAT) was designed to help facilitate activists, journalists, researchers, and other social good organizations to analyze and visualize harmful online trends such as hate, mis-, and disinformation.',

  value1: {
    title: 'What we provide',
    detail: 'We provide easy to use tools that help you tell a story about something happening at a large scale online.'
  },
  value2: {
    title: 'Simple & Rigorous',
    detail: 'The tools are designed to be quick and simple so that users can quickly respond to things like disinformation and hate as they occur, while also having the tools be rigorous enough to have applications in scholarly research.'
  },
  value3: {
    title: "We're open source",
    detail: 'All our tools are {openSource}, so you can see our work and modify it for your own needs.',
    openSource: 'open source'
  },

  contact: {
    title: 'Contact Us',
    detail: 'Find us on {gitHosting} or {twitter} on Twitter. SMAT is fiscally hosted and small or large contributions can be made through our {donation} page which help to develop new tools and data sources. Descriptions of bugs or desired features welcomed as well!'
  },

  partners: {
    title: 'Our Partners:'
  }
}
