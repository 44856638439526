<template>
  <div id="app">
    <q-layout>
      <q-page-container>
        <ThePage />
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import ThePage from '@/components/ThePage'

export default {
  components: {
    ThePage
  },
  data () {
    return {
      showSettingsBar: true,
      showMenu: false,
      large: false,
      drawer: true,
      miniState: false
    }
  },
  methods: {
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    },
  },
  watch: {
    // when the user clicks Search, this variable is incremented, so we watch
    // it to find out when the user wants to search. a weird kind of event bus
    'doSearch.count': function () {
      if (!this.large) {
        this.showSettingsBar = false
      }
    },
  },
  created () {
    this.updateLarge()
    window.addEventListener('resize', this.$store.commit('setWindowWidth'))
    window.addEventListener('resize', this.updateLarge)
  }
}
</script>

<style lang="scss">
@import "global";
</style>

<style scoped lang="scss">
@import "~@openfonts/sarabun_all/index.css";

a {
  text-decoration: none !important;
}

#app {
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
}

.toolbar {
  background: var(--color-off-white);
}

.sidebar {
  background: var(--color-off-white);
  height: 100%;
  width: 100%;
  padding: var(--spacing-3) var(--spacing-4);
  text-align: left;
  color: var(--color-white);
  .sidebar-title {
    font-size: 20px;
    font-weight: bold;
  }
}

.mini-slot {
  background: var(--color-dark-bg);
}

.close-sidebar {
  top: 10px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}


</style>
