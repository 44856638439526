<template>
  <div class='promo'>
<!--    <img :src='ncri' :height='height' /> -->
  </div>
</template>

<script>
// import ncri from '@/assets/ncri.svg'

export default {
  props: {
    height: { type: Number, default: 40 }
  },
  data () {
    return {
      //      ncri
    }
  }
}
</script>

<style scoped lang="scss">
.promo {
  padding: 40px;

  img {
    filter: grayscale(100%) opacity(70%);
    transition: filter 0.3s ease-in;

    &:hover {
      filter: none;
    }
  }
}
</style>

