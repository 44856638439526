<template>
  <div class='container'>
    <div class='project'>
      <div class='heading'>
        <h2 class='text-title-1'>
          SMAT is now Open Measures!
        </h2>
        <div class='text-body-1 text-intro'>
          <div class='logo-container'>
            <img :src='smatLogo' class='logo' />
            <div class='arrow'>&rarr;</div>
            <img :src='omLogo' class='logo' />
          </div>
          <a href="https://openmeasures.io" class="text-url">openmeasures.io</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import om from '@/assets/openmeasures-full.png'
import smat from '@/assets/smat-full.svg'

export default {
  components: {
  },
  props: {
    value: Boolean,
    tool: String
  },
  data () {
    return {
    }
  },
  computed: {
    omLogo () {
      return om
    },
    smatLogo () {
      return smat
    },
    windowWidth () {
      return this.$store.state.general.windowWidth
    }
  },
}
</script>

<style scoped lang="scss">

.text-url {
  color: var(--color-dark-blue);
  font-weight: bold;
  line-height: 42px;
  font-size: 32px;
  text-decoration: underline !important;  /* Add !important */
}
  .logo-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }

  .logo {
    width: auto;  /* Adjust as needed */
    height: 80px;
  }

  .arrow {
    font-size: 6em;
    align-self: center;
    margin-bottom: 20px;
  }
  .container {
    // --gap: 30px;
    // --value-min-width: 230px;
    // --spacing-max-content-width: 1192px;
    // --card-max-width: 400px;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    background-color: var(--color-off-white);
  }

  hr {
    border-color: var(--color-dark-dividers);
    width: 100vw !important;
    position: absolute;
    left: 0;
    margin: 0;
  }

  .clickable:hover {
    cursor: pointer;
  }

  .tool-button {
    width: 32%;
    max-width: var(--card-max-width);
    min-width: 310px;

    position: relative;
    cursor: pointer;
    user-select: none;
    color: var(--color-dark-text);
    border-radius: var(--border-radius-1);
    display: grid;
    grid-auto-flow: column;
  }

  .project {
    padding: var(--spacing-section) 7rem 0;

    font-family: var(--primary-font);
    color: var(--color-black-new);
    background: var(--color-off-white);

    h2 {
      margin: 0;
      text-align: center;
      padding-bottom: 24px;
    }

    img {
      max-width: 100%;
    }

    a {
      color: var(--color-primary);
      text-decoration: none !important;
    }


    .text-intro {
      max-width: 629px;
      text-align: center;
      margin-bottom: 3rem;
    }

    .text-heading {
      text-transform: uppercase;
      justify-content: start;
      font-size: 12px;
      font-weight: 700;
      align-items: flex-start;
      letter-spacing: 1px;
      padding: 32px 0 24px 0;
    }

    .text-title {
      padding-bottom: 16px;
    }

    .text-subtitle {
      padding-bottom: var(--spacing-3);
    }

    .text-detail {
      padding-bottom: 3rem;
    }

    .text-footer {
      border-bottom: 5px solid var(--color-primary);
      max-width: 60px;
    }

    .heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .shifted-columns {
      display: grid;
      grid-template-columns: 3fr 2fr;
      margin-bottom: var(--spacing-section);
      .left {
        flex-shrink: 0;
        padding-right: var(--spacing-3);
      }
      .right {
        flex-shrink: 0;
        padding-left: var(--spacing-3);
      }
      .centred {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .right-shifted {
      grid-template-columns: 2fr 3fr;
    }

    .left-shifted {
      grid-template-columns: 3fr 2fr;
      .left {
        grid-row: 1;
        grid-column: 1;
      }
      .right {
        grid-row: 1;
        grid-column: 2;
      }

    }

    .collection {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 32px;
      align-items: center;

      img {
        height: 28px;
        max-width: 258px;
      }
    }

    .tools {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: var(--spacing-section);

      .tool-link {
        text-decoration: none !important;
      }
    }

    .featured {
      margin-bottom: var(--spacing-section);
    }

    .values {
      .text-heading {
        padding-top: 0;
      }
    }

    .premium {
      display: flex;
      padding-top: var(--spacing-5);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: calc(64px - 2rem) 0;
      h2 {
        font-size: 32px;
      }
      .text-intro {
        font-size: 18px;
        max-width: 708px;
      }
    }

    .about-header {
        min-height: var(--spacing-section);
    }

    .about {
      .text-title {
        padding-bottom: 40px;
      }
      .text-heading {
        padding-top: 0;
      }
      .shifted-columns {
        margin-bottom: calc(64px - 2rem);
      }
    }

    .last {
      margin-bottom: var(--spacing-section);
      .left {
        max-width: 464px;
      }
      .text-detail {
        padding-bottom: 0;
      }
    }

    .even-grid {
      display: grid;
      grid-template-columns: auto auto;
      div {
        max-width: 450px;
        margin: 0 auto 0 0;
      }
      padding-bottom: 0;
      .text-detail {
        // 2rem is the grid-spacing vertically
        padding-bottom: var(--spacing-section);
      }
    }

    .footer {
      .text-heading {
        padding-top: 32px;
        padding-bottom: 0;
        margin-bottom: -10px;
      }
      img {
        height: 60px;
        padding: 0 var(--spacing-1);
        margin-bottom: var(--spacing-5);
      }
    }
  }


  @media (min-width: 1024px) {
    .project {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      grid-gap: 2rem;
    }
  }

  @media (max-width: 1190px) {
    .project {
      padding: 10rem 2rem 0;
      .tools {
        justify-content: center;
        .tool-button {
          width: var(--card-max-width);
          padding: 1rem;
        }
      }
      .premium {
        padding: 64px 0;
      }
      .last {
        .text-detail {
          padding-bottom: 3rem;
        }
      }
    }
    .project .footer .collection {
      justify-content: space-around;
    }

  }


  // calc(3*var(--value-min-width) + 4*var(--gap))
  // = 690 + 80 = 770
  @media (max-width: 860px) {
    .left > img, .right > img {
      padding-top: 76px;
    }
    .project > .tools {
      grid-template-columns: 1fr 1fr;
      .tool-button {
        padding: 1rem 0;
      }
    }

    .project > .values > .shifted-columns, .project .shifted-columns {
      display: flex;
      flex-direction: column;
      .left, .right {
        padding: 0;
      }
    }

    .project > .even-grid {
      grid-template-columns: auto;
    }

  }

  @media (max-width: 720px) {
    .project .featured .collection {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: var(--spacing-5);
      img {
        max-width: 100%;
      }
    }
    .project .shifted-columns {
      margin-bottom: 64px;
    }

    .project .featured {
      margin-bottom: 128px;
    }

    .project .values .p128-tablet {
      margin-bottom: 128px;
    }
  }

  // calc(2*var(--value-min-width) + 3*var(--gap))
  // = 460 + 60 = 520
  @media (max-width: 520px) {
    .text-title-1 {
      font-size: 32px;
      line-height: 42px;
      padding-bottom: 16px;
    }
    .project {
      padding: 80px 2rem 0;
      .text-intro {
        font-size: 16px;
        margin-bottom: 64px;
      }

      .text-title {
        font-size: 24px;
        line-height: 31px;
      }

      .text-detail {
        font-size: 16px;
        line-height: 26px;
      }

      .text-subtitle {
        font-size: 20px;
        line-height: 26px;
        padding-bottom: 16px;
      }

      .tools {
        grid-template-columns: auto;
        padding-bottom: 80px;
        :deep(.tool-button:first-of-type) {
          padding-top: 0;
        }
      }

      .premium {
        h2 {
          font-size: 24px;
          line-height: 31px;
          padding-bottom: 16px;
        }
        .text-intro {
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 24px;
        }
      }

      .values {
        grid-template-columns: 1fr;
        .p128-tablet {
          margin-bottom: 104px;
        }
        .text-subtitle {
          font-size: 20px;
        }
        .text-detail {
          padding-bottom: 40px;
        }
      }

      .about {
        .shifted-columns {
          margin-bottom: 64px;
          .text-title {
            padding-bottom: 0;
            margin-bottom: 40px;
          }
        }
      }

      .last {
        .text-detail {
          padding-bottom: 64px;
        }
      }

      .footer {
        .collection {
          margin-top: 50px;
        }
      }
    }
  }
</style>
