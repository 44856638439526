module.exports = {
  nav: {
    home: 'Inicio',
    timeline: 'Línea del tiempo',
    hashtags: 'Hashtags',
    links: 'Contador de enlaces',
    activity: 'Actividad',
    about: 'Acerca',
  },
  toolPicker: {
    label: 'SMAT tool',
    changeTools: 'Change tools',
    modalTitle: 'Choose a tool to begin your analysis',
    modalSubtitle: 'Each tool below will run a different analysis based on your input and return downloadable results',
  },
  toolDescription: {
    timeline: 'The timeline functionality takes a search term and makes a graph of how often it was written over time',
    hashtags: 'The hashtag functionality takes a search term and creates a bar graph of related hashtags',
    links: 'The link counter functionality takes a search term and returns a bar graph counting links in comments with that term',
    activity: 'This takes a search term and creates a bar graph of a particular field. For most sources this is the author of the content',
  },
  setting: {
    term: 'Término de búsqueda',
    termPlaceholder: 'Ingrese el término de búsqueda',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    pickWebsite: 'Sitio web de redes sociales para buscar',
    aggBy: {
      label: 'Aggregate Reddit by',
      author: 'Author',
      subreddit: 'Subreddit',
    },
    numberHashtags: 'Número de hashtags',
    numberUrls: 'Número de urls',
    interval: 'Intervalo',
    times: {
      hour: 'hour',
      day: 'day',
      week: 'week',
      month: 'month',
    },
    timely: {
      hour: 'A cada hora',
      day: 'Diario',
      week: 'Semanal',
      month: 'Mensual',
    },
    limit: 'Límite',
    showChangepoint: 'Show changepoint',
    button: {
      timeline: 'Ver resultados de la línea de tiempo',
      hashtags: 'Ver resultados del detector de hashtag',
      urls: 'Ver gráfico de URL',
      activity: 'Ver actividad',
    },
  },
  chart: {
    timelineOn: 'Timeline on ',
    hashtagsWith: 'Hashtags Occurring with ',
    linkCountOn: 'Link Count on ',
    activityOn: 'Activity on ',
    postsPer: "Posts containing '{term}' per {interval}",
    popularLinksWith: "Popular links occurring with '{term}'",
    termAggBy: "'{term}' aggregated over {aggBy}s",
    downloadPNG: 'Save as PNG',
  },
  table: {
    date: 'Fecha',
    usage: 'Uso',
    urls: 'URLs',
    urlCount: 'Conteo de URLs',
    user: 'User',
    count: 'Conteo',
    hashtags: 'Hashtags',
    hashtagFreq: 'Frecuencia de hashtag',
    viewFor: 'Table View for ',
    view: 'Vista de tabla',
    downloadCSV: 'Download CSV',
  },
  data: {
    beforeChangepoint: 'Before {site} changepoint',
    afterChangepoint: 'After {site} changepoint',
  },
}